<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Configuração do Distribuidor</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            
            <v-row style="height: 100px">
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Distribuidor</label>
                    <v-combobox
                        v-model="distribuidorConfigRequest.distribuidorSelected"
                        :rules=[validations.required]
                        :items="listDistribuidor"
                        item-text="nameAndCnpj"
                        item-value="cnpjProcessed"
                        clearable
                        outlined
                        :loading="loadingDistribuidor"
                        @change="listCampanhas"
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Condições de Pagamento</label>
                    <v-combobox
                        v-model="distribuidorConfigRequest.condicaoPagamentoSelected"
                        :items="listCondicaoPagamento"
                        :rules=[validations.required]
                        item-text="descricao"
                        item-value="id"
                        clearable
                        outlined
                        multiple
                        small-chips
                    ></v-combobox>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Marcas comercializadas</label>
                    <v-combobox
                        v-model="distribuidorConfigRequest.marcasContratoSelected"
                        :items="listMarca"
                        :rules=[validations.required]
                        item-text="descricao"
                        item-value="id"
                        clearable
                        outlined
                        multiple
                        small-chips
                    ></v-combobox>
                </v-col>
            </v-row>

            <br />

            <v-tabs
                v-model="tab"
                fixed-tabs
            >
                <v-tab key="1">
                    Descontos / Campanhas
                </v-tab>
                <v-tab key="2">
                    Metas
                </v-tab>
            </v-tabs>


            <v-tabs-items v-model="tab">                
                <!-- DESCONTOS / CAMPANHAS -->
                <v-tab-item key="1">
                    <br />

                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-icon color="primary" left>
                                            mdi-percent-outline
                                        </v-icon>
                                        Desconto do Distribuidor
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                        
                            <v-expansion-panel-content>


                                <v-row style="height: 25px;">
                                    <v-col 
                                        cols="12"
                                        lg="8"
                                        md="5"
                                        sm="12"
                                    >
                                        <label>Marca</label>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="5"
                                        sm="12"
                                    >
                                        <label>Desconto</label>
                                    </v-col>
                                </v-row>
                                <v-row v-for="(item, index) in distribuidorConfigRequest.listDesconto" :key="index" style="height: 80px;" >
                                    <v-col 
                                        cols="12"
                                        lg="8"
                                        md="5"
                                        sm="12"
                                    >
                                        <v-combobox
                                            v-model="item.marcaSelected"
                                            :items="listMarca"
                                            item-text="descricao"
                                            item-value="id"
                                            clearable
                                            outlined
                                        ></v-combobox>
                                    </v-col>    
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="5"
                                        sm="12"
                                    >
                                        <v-text-field
                                            v-model="item.desconto" 
                                            suffix=" %"
                                            v-formatMoney="percFormat"
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="2"
                                        lg="1"
                                        md="2"
                                        sm="2"
                                        :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                    >
                                        <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    class="btnActions"
                                                    outlined
                                                    fab
                                                    x-small
                                                    v-on="on"
                                                    @click="addNewItem(item)" 
                                                    color="blue" 
                                                    small
                                                    :key="index"
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Adicionar Novo Item</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    class="btnActions"
                                                    outlined
                                                    fab
                                                    x-small
                                                    v-on="on"
                                                    @click="deleteItem(index)" 
                                                    color="red" 
                                                    small
                                                    :disabled="distribuidorConfigRequest.listDesconto.length <= 1"
                                                >
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Excluir Item</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                            
                    </v-expansion-panels>

                    <br />

                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-icon color="primary" left>
                                            mdi-calendar-star
                                        </v-icon>
                                        Campanha de Estoque
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                        
                            <v-expansion-panel-content>
                    
                                <v-row style="height: 100px">
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Selecione uma Campanha</label>
                                        <v-combobox
                                            v-model="distribuidorConfigRequest.campanhaEstoqueSelected"
                                            :items="listCampanhaEstoque"
                                            item-text="descricao"
                                            item-value="id"
                                            clearable
                                            outlined
                                            multiple
                                            small-chips
                                        ></v-combobox>
                                    </v-col>
                                </v-row>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                            
                    </v-expansion-panels>

                    <br />

                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-icon color="primary" left>
                                            mdi-gold
                                        </v-icon>
                                        Campanha Ouro Prata
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                        
                            <v-expansion-panel-content>
                    
                                <v-row style="height: 100px">
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Selecione uma Campanha</label>
                                        <v-combobox
                                            v-model="distribuidorConfigRequest.campanhaOuroPrataSelected"
                                            :items="listCampanhaOuroPrata"
                                            item-text="descricao"
                                            item-value="id"
                                            clearable
                                            outlined
                                            multiple
                                            small-chips
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                                

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                            
                    </v-expansion-panels>

                    <br />

                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-icon color="primary" left>
                                            mdi-account-credit-card-outline
                                        </v-icon>
                                        Campanha de Crédito
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                        
                            <v-expansion-panel-content>
                    
                                <v-row style="height: 100px">
                                    <v-col 
                                        cols="12"
                                        lg="8"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Selecione uma Campanha</label>
                                        <v-combobox
                                            v-model="distribuidorConfigRequest.campanhaCreditoSelected"
                                            :items="listCampanhaCredito"
                                            item-text="descricao"
                                            item-value="id"
                                            clearable
                                            outlined
                                            @change="setValorCredito()"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col 
                                        style="display: flex; align; align-content: center; align-items: center;"
                                        cols="12"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    outlined
                                                    v-on="on"
                                                    @click="abrirDialogSaldoCampanha()"
                                                >
                                                    Visualizar Saldo de Campanha
                                                </v-btn>
                                            </template>
                                            <span>Visualizar saldo de campanha dos pedidos</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>                        

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                            
                    </v-expansion-panels>
                    
                    <br />
                </v-tab-item>

                <!-- METAS -->
                <v-tab-item key="2">
                    <br />
                    <v-row style="height: 25px;">
                        <v-col 
                            cols="12"
                            lg="4"
                            md="3"
                            sm="12"
                        >
                            <label>Mês/Ano</label>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="3"
                            sm="12"
                        >
                            <label>Marca</label>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <label>Valor</label>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in distribuidorConfigRequest.listMetas" :key="index" style="height: 80px;" >  
                        <v-col 
                            cols="12"
                            lg="4"
                            md="3"
                            sm="12"
                        >
                            <v-text-field
                                v-model="item.dataMetaFormatted"
                                v-mask="'##/####'"
                                single-line
                                outlined
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="3"
                            sm="12"
                        >
                            <v-combobox
                                v-model="item.marcaSelected"
                                :items="listMarca"
                                item-text="descricao"
                                item-value="id"
                                clearable
                                outlined
                            ></v-combobox>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-text-field
                                v-model="item.valor" 
                                suffix=" %"
                                v-formatMoney="moneyFormat"
                                single-line
                                outlined
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="2"
                            lg="1"
                            md="2"
                            sm="2"
                            :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                        >
                            <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="btnActions"
                                        outlined
                                        fab
                                        x-small
                                        v-on="on"
                                        @click="addNewItemMetas(item)" 
                                        color="blue" 
                                        small
                                        :key="index"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Adicionar Nova Meta</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="btnActions"
                                        outlined
                                        fab
                                        x-small
                                        v-on="on"
                                        @click="deleteItemMetas(index)" 
                                        color="red" 
                                        small
                                        :disabled="distribuidorConfigRequest.listMetas.length <= 0"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir Meta</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>

            <ActionButtons
                :request="distribuidorConfigRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

        <DialogPedidosCampanha 
            :showDialogPedidosCampanha.sync="dialogSaldoCampanha.show"
            :idAux="dialogSaldoCampanha.idAux"
            :idDistribuidor="dialogSaldoCampanha.idDistribuidor"
        />

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import DialogPedidosCampanha from "@/components/Layout/DialogPedidosCampanha";
    import { formaterDecimalBRServer } from '@/utilities/Utils';

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            DialogPedidosCampanha
        },

        data: () => ({

            validForm: true,

            distribuidorConfigRequest: {
                id: 0,
                distribuidorSelected: null,
                condicaoPagamentoSelected: null,
                marcasContratoSelected: null,
                valorCredito: "0,00",
                campanhaEstoqueSelected: null,
                campanhaOuroPrataSelected: null,
                campanhaCreditoSelected: null,
                listDesconto: [],
            },

            listMarca: [],
            listDistribuidor: [],
            listCondicaoPagamento: [],
            loadingDistribuidor: false,

            listCampanhaEstoque: [],
            listCampanhaOuroPrata: [],
            listCampanhaCredito: [],

            dialogSaldoCampanha: {
                show: false,
                idAux: 0,
                idDistribuidor: "0"
            },

            tab: null,

            loading: false,
            
            validations: {
                required: required,
            },

            moneyFormat: {
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: ',',
                precision: 2,
            },
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async deleteItem(index) {
                this.distribuidorConfigRequest.listDesconto.splice(index, 1);
                
                let lastIndex = this.distribuidorConfigRequest.listDesconto.length - 1;

                this.distribuidorConfigRequest.listDesconto[lastIndex].showAddNewItem = true;
            },
            
            async deleteItemMetas(index) {
                this.distribuidorConfigRequest.listMetas.splice(index, 1);
                
                let lastIndex = this.distribuidorConfigRequest.listMetas.length - 1;
                
                if (this.distribuidorConfigRequest.listMetas.length <= 0) {
                    this.addNewItemMetas();
                }
                else {

                    this.distribuidorConfigRequest.listMetas[lastIndex].showAddNewItem = true;
                }
            },

            async addNewItem(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                this.distribuidorConfigRequest.listDesconto.push({
                    marcaSelected: null,
                    desconto: "0,00",
                    showAddNewItem: true
                })
            },

            async addNewItemMetas(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                if (this.distribuidorConfigRequest.listMetas == null || this.distribuidorConfigRequest.listMetas == undefined) {
                    this.distribuidorConfigRequest.listMetas = [];
                }

                this.distribuidorConfigRequest.listMetas.push({
                    dataMeta: "",
                    marcaSelected: null,
                    valor: "0,00",
                    showAddNewItem: true
                })
            },

            async getLists() {
                
                this.listCondicaoPagamento = await this.$store.dispatch("condicaoPagamentoModule/List");
                this.loadingDistribuidor = true;
                this.listMarca = await this.$store.dispatch("marcaModule/List");
                this.listDistribuidor = await this.$store.dispatch("empresaModule/ListDistribuidor");
                this.loadingDistribuidor = false;
            },

            async listCampanhas() {

                let estado = "";
                this.listCampanhaEstoque = [];
                this.listCampanhaOuroPrata = [];
                this.listCampanhaCredito = [];

                if (this.distribuidorConfigRequest.distribuidorSelected != null && this.distribuidorConfigRequest.distribuidorSelected != undefined) {
                    estado = this.distribuidorConfigRequest.distribuidorSelected.state;
                }

                if (estado != "") {
                    this.listCampanhaEstoque = await this.$store.dispatch("campanhaEstoqueModule/ListByEstado", estado);
                    this.listCampanhaOuroPrata = await this.$store.dispatch("campanhaOuroPrataModule/ListByEstado", estado);
                    this.listCampanhaCredito = await this.$store.dispatch("campanhaCreditoModule/ListByEstado", estado);
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("distribuidorConfigModule/GetById", this.id);

                    if (response.success) {
                        this.distribuidorConfigRequest = response.result;

                        if (this.distribuidorConfigRequest.listaCondicaoPagamento != null && this.distribuidorConfigRequest.listaCondicaoPagamento != undefined && this.distribuidorConfigRequest.listaCondicaoPagamento != '') {
                            try {
                                this.distribuidorConfigRequest.condicaoPagamentoSelected = JSON.parse(this.distribuidorConfigRequest.listaCondicaoPagamento);
                            }
                            catch {
                                this.distribuidorConfigRequest.condicaoPagamentoSelected = null;
                            }
                        }

                        if (this.distribuidorConfigRequest.listaCampanhaEstoque != null && this.distribuidorConfigRequest.listaCampanhaEstoque != undefined && this.distribuidorConfigRequest.listaCampanhaEstoque != '') {
                            try {
                                this.distribuidorConfigRequest.campanhaEstoqueSelected = JSON.parse(this.distribuidorConfigRequest.listaCampanhaEstoque);
                            }
                            catch {
                                this.distribuidorConfigRequest.campanhaEstoqueSelected = null;
                            }
                        }

                        if (this.distribuidorConfigRequest.listaCampanhaCredito != null && this.distribuidorConfigRequest.listaCampanhaCredito != undefined && this.distribuidorConfigRequest.listaCampanhaCredito != '') {
                            try {
                                this.distribuidorConfigRequest.campanhaCreditoSelected = JSON.parse(this.distribuidorConfigRequest.listaCampanhaCredito);
                            }
                            catch {
                                this.distribuidorConfigRequest.campanhaCreditoSelected = null;
                            }
                        }

                        if (this.distribuidorConfigRequest.listaCampanhaOuroPrata != null && this.distribuidorConfigRequest.listaCampanhaOuroPrata != undefined && this.distribuidorConfigRequest.listaCampanhaOuroPrata != '') {
                            try {
                                this.distribuidorConfigRequest.campanhaOuroPrataSelected = JSON.parse(this.distribuidorConfigRequest.listaCampanhaOuroPrata);
                            }
                            catch {
                                this.distribuidorConfigRequest.campanhaOuroPrataSelected = null;
                            }
                        }

                        if (this.distribuidorConfigRequest.listaMarcasContrato != null && this.distribuidorConfigRequest.listaMarcasContrato != undefined && this.distribuidorConfigRequest.listaMarcasContrato != '') {
                            try {
                                this.distribuidorConfigRequest.marcasContratoSelected = JSON.parse(this.distribuidorConfigRequest.listaMarcasContrato);
                            }
                            catch {
                                this.distribuidorConfigRequest.marcasContratoSelected = null;
                            }
                        }
                        
                        if (this.distribuidorConfigRequest.listDesconto !== null && this.distribuidorConfigRequest.listDesconto !== undefined) {
                            this.distribuidorConfigRequest.listDesconto.forEach(itemDesconto => {
                                itemDesconto.desconto = itemDesconto.descontoFormatted;
                            })
                        }
                        
                        if (this.distribuidorConfigRequest.listMetas !== null && this.distribuidorConfigRequest.listMetas !== undefined) {
                            this.distribuidorConfigRequest.listMetas.forEach(itemMetas => {
                                itemMetas.valor = itemMetas.valorFormatted;
                            })
                        }

                        if (this.distribuidorConfigRequest.listDesconto.length == 0) {
                            this.addNewItem();
                        }

                        if (this.distribuidorConfigRequest.listMetas.length == 0) {
                            this.addNewItemMetas();
                        }

                        await this.listCampanhas();
                    }
                }
                else {
                    this.distribuidorConfigRequest.status = 1;
                    this.addNewItem();
                    this.addNewItemMetas();
                }
            },

            async cancel() {
                this.$router.push({ path: "/distribuidor/distribuidorConfigList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.distribuidorConfigRequest.id = this.id;
                    this.distribuidorConfigRequest.valorCredito = formaterDecimalBRServer(this.distribuidorConfigRequest.valorCredito);

                    if (this.distribuidorConfigRequest.distribuidorSelected != null && this.distribuidorConfigRequest.distribuidorSelected != undefined) {
                        this.distribuidorConfigRequest.idDistribuidor = this.distribuidorConfigRequest.distribuidorSelected.id;
                    }

                    if (this.distribuidorConfigRequest.campanhaCreditoSelected != null && this.distribuidorConfigRequest.campanhaCreditoSelected != undefined) {
                        this.distribuidorConfigRequest.idCampanhaCredito = this.distribuidorConfigRequest.campanhaCreditoSelected.id;
                    }

                    if (this.distribuidorConfigRequest.condicaoPagamentoSelected != null && this.distribuidorConfigRequest.condicaoPagamentoSelected != undefined) {

                        let listIDCondicaoPagamento = '';

                        this.distribuidorConfigRequest.condicaoPagamentoSelected.forEach(itemCondicaoPagamento => {
                            listIDCondicaoPagamento += `${itemCondicaoPagamento.id};`
                        })

                        this.distribuidorConfigRequest.listaCondicaoPagamento = listIDCondicaoPagamento;
                    }

                    if (this.distribuidorConfigRequest.campanhaEstoqueSelected != null && this.distribuidorConfigRequest.campanhaEstoqueSelected != undefined) {

                        let listIDCampanha = '';

                        this.distribuidorConfigRequest.campanhaEstoqueSelected.forEach(itemCampanha => {
                            listIDCampanha += `${itemCampanha.id};`
                        })

                        this.distribuidorConfigRequest.listaCampanhaEstoque = listIDCampanha;
                    }

                    if (this.distribuidorConfigRequest.campanhaOuroPrataSelected != null && this.distribuidorConfigRequest.campanhaOuroPrataSelected != undefined) {

                        let listIDCampanha = '';

                        this.distribuidorConfigRequest.campanhaOuroPrataSelected.forEach(itemCampanha => {
                            listIDCampanha += `${itemCampanha.id};`
                        })

                        this.distribuidorConfigRequest.listaCampanhaOuroPrata = listIDCampanha;
                    }

                    if (this.distribuidorConfigRequest.marcasContratoSelected != null && this.distribuidorConfigRequest.marcasContratoSelected != undefined) {

                        let listIDMarcas = '';

                        this.distribuidorConfigRequest.marcasContratoSelected.forEach(itemMarca => {
                            listIDMarcas += `${itemMarca.id};`
                        })

                        this.distribuidorConfigRequest.listaMarcasContrato = listIDMarcas;
                    }

                    let newListDesconto = [];
                    let listDesconto = [...this.distribuidorConfigRequest.listDesconto];

                    this.distribuidorConfigRequest.listDesconto = [];

                    if (listDesconto !== null && listDesconto !== undefined) {
                        listDesconto.forEach(itemDesconto => {
                            if (itemDesconto && itemDesconto.marcaSelected) {
                                newListDesconto.push({
                                    id: itemDesconto.id,
                                    idMarca: itemDesconto.marcaSelected.id,
                                    desconto: formaterDecimalBRServer(itemDesconto.desconto)
                                })

                                this.distribuidorConfigRequest.listDesconto = newListDesconto;
                            }
                        })
                    }

                    let newListMetas = [];
                    let listMetas = [...this.distribuidorConfigRequest.listMetas];


                    if (listMetas.length == 1 && listMetas[0].dataMeta == '') {
                        this.distribuidorConfigRequest.listMetas = [];
                    }
                    else {

                        if (listMetas !== null && listMetas !== undefined) {
                            listMetas.forEach(itemMetas => {
                                if (itemMetas && itemMetas.dataMetaFormatted != '' && itemMetas.marcaSelected != null && itemMetas.marcaSelected != undefined) {
                                    newListMetas.push({
                                        id: itemMetas.id,
                                        dataMeta: `${itemMetas.dataMetaFormatted.split('/')[1]}-${itemMetas.dataMetaFormatted.split('/')[0]}-01`,
                                        idMarca: itemMetas.marcaSelected.id,
                                        valor: formaterDecimalBRServer(itemMetas.valor)
                                    })

                                    this.distribuidorConfigRequest.listMetas = newListMetas;
                                }
                            })
                        }
                    }

                    const result = await this.$store.dispatch("distribuidorConfigModule/CreateUpdate", this.distribuidorConfigRequest);

                    if (result.success === true) {
                        this.showToast("success", "Sucesso!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Aviso!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            },

            setValorCredito() {

                if (this.distribuidorConfigRequest.campanhaCreditoSelected != null && this.distribuidorConfigRequest.campanhaCreditoSelected != undefined) {

                    if (this.distribuidorConfigRequest.valorCredito == "0,00" || this.distribuidorConfigRequest.valorCredito == "" || this.distribuidorConfigRequest.valorCredito == null || this.distribuidorConfigRequest.valorCredito == undefined) {
                        this.distribuidorConfigRequest.valorCredito = this.distribuidorConfigRequest.campanhaCreditoSelected.valorFormatted;
                    }
                }

            },

            abrirDialogSaldoCampanha() {
                
                let idDistribuidor = "0";

                if (this.distribuidorConfigRequest.distribuidorSelected != null && this.distribuidorConfigRequest.distribuidorSelected != undefined) {
                    idDistribuidor = this.distribuidorConfigRequest.distribuidorSelected.id;
                }

                this.dialogSaldoCampanha = {
                    show: true,
                    idAux: Math.random(),
                    idDistribuidor
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>


<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>